import { useState } from "react";
import Header from "./Header";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
export default function Category() {
  const [show, setShow] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubcategory] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [Category, setCategory] = useState([]);

  const data = [
    {
      Category: "Outdooor signages1",
      subcategory: [
        "Glow sign board",
        "Nanlite Board",
        "Glow sign board",
        "Nanlite Board",
      ],
    },
    {
      Category: "Outdooor signages2",
      subcategory: [
        "Glow sign board",
        "Nanlite Board",
        "Glow sign board",
        "Nanlite Board",
      ],
    },
    {
      Category: "Outdooor signages3",
      subcategory: [
        "Glow sign board",
        "Nanlite Board",
        "Glow sign board",
        "Nanlite Board",
      ],
    },
    {
      Category: "Outdooor signages4",
      subcategory: [
        "Glow sign board",
        "Nanlite Board",
        "Glow sign board",
        "Nanlite Board",
      ],
    },
    {
      Category: "Outdooor signages6",
      subcategory: [
        "Glow sign board",
        "Nanlite Board",
        "Glow sign board",
        "Nanlite Board",
      ],
    },
    {
      Category: "Outdooor signages7",
      subcategory: [
        "Glow sign board",
        "Nanlite Board",
        "Glow sign board",
        "Nanlite Board",
      ],
    },
  ];

  const filterCategory = data.map((item) => item.Category);
  const filterdata = filterCategory.filter((ele) =>
    ele.toLocaleLowerCase().includes(searchCategory)
  );

  return (
    <>
      <Header />

      <div className="row  m-auto containerPadding">
        <div className="row  m-auto containerPadding">
          <div className="col-md-12 ">
            <Button
              className="col-md-2  "
              style={{ marginRight: "5px" }}
              onClick={handleShow}
            >
              Add Category
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label htmlFor="subcategory">Add Category</Form.Label>
                <Form.Control
                  type="text"
                  id="Category"
                  placeholder="Enter Category"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>

            <Button
              className="col-md-2 "
              onClick={handleShow1}
              style={{ marginLeft: "5px" }}
            >
              Add subcategory
            </Button>
            <Modal show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>Add subcategory</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label htmlFor="subcategory">Add subcategory</Form.Label>
                <Form.Control
                  placeholder="Enter Subcategory"
                  type="text"
                  id="subcategory"
                  aria-describedby="subcategory"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose1}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="table-container containerPadding">
          <div className="table-wrapper1">
            <Table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="text"
                      onChange={(e) => setSearchCategory(e.target.value)}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      onChange={(e) => setSearchSubcategory(e.target.value)}
                    />{" "}
                  </th>
                </tr>
                <tr>
                  <th>Category</th>
                  <th> Subcategory</th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.Category}</td>
                      <td>
                        <ul>
                          {item.subcategory.map((ele, innerIndex) => (
                            <li key={innerIndex}>{ele}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>{" "}
          </div>
        </div>
      </div>
    </>
  );
}
