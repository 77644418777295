import React from "react";

export default function Footer() {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f9f9f9",
        position: "sticky",
        bottom: "0%",
      }}
      className="row text-center m-auto"
    >
      <p className="m-auto">
        {" "}
        Designed & Developed by Nakshatra Namaha Creations
      </p>
    </div>
  );
}
